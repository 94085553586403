.row {
	border-top: $border-primary;
	display: flex;
	flex-direction: column;

	@include media($m-screen-up) {
		flex-direction: row;
	}

	&--last {
		padding-bottom: 0;
	}
}

.row__left {
	width: 100%;

	@include media($m-screen-up) {
		padding-right: $gutter-large;
		width: 50%;
	}

	@include media($l-screen-up) {
		width: 40%;
	}
}

.row__right {
	width: 100%;
	order: -1;
	padding-bottom: $gutter;

	@include media($m-screen-up) {
		width: 50%;
		order: 0;
		padding-bottom: 0;
	}

	@include media($l-screen-up) {
		width: 60%;
	}
}


.row__heading {
	font-family: $font-body;
	text-transform: none;
	font-size: rem(24);
}

.row__text {
	font-size: rem($text-small);
}

.row__image {
  position: relative;

  img {
    width: 100%;
  }
}
