@use "sass:math";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

@import "utilities/normalize";
@import "utilities/font-face";
@import "utilities/functions";
@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/media-queries";
@import "utilities/classes";
@import "utilities/grid";

@import "base/base";
@import "base/typography";

@import "atoms/container";
@import "atoms/divider";
@import "atoms/icon";
@import "atoms/image-filter";
@import "atoms/link";
@import "atoms/loading-overlay";
@import "atoms/top-link";
@import "atoms/back";
@import "atoms/people-link";
@import "atoms/filter";
@import "atoms/filter-text";
@import "atoms/circle-number";
@import "atoms/switch";

@import "molecules/blockquote";
@import "molecules/breakout";
@import "molecules/button";
@import "molecules/card";
@import "molecules/glide";
@import "molecules/header";
@import "molecules/nav";
@import "molecules/newsletter";
@import "molecules/reveal";
@import "molecules/row";
@import "molecules/subnav";
@import "molecules/person";
@import "molecules/people-links";
@import "molecules/filters";
@import "molecules/quote";
@import "molecules/award";
@import "molecules/video";
@import "molecules/rows";

@import "organisms/featured";
@import "organisms/footer";
@import "organisms/form";
@import "organisms/grid";
@import "organisms/hero";
@import "organisms/section";
@import "organisms/cols";
@import "organisms/people";
@import "organisms/profile";
@import "organisms/related";
@import "organisms/explore";
@import "organisms/section-slider";
@import "organisms/carousel";
@import "organisms/search";

@import "templates/practice/practice";
@import "templates/practice/approach";
@import "templates/projects/projects";
@import "templates/projects/project";
@import "templates/projects/project-row";
@import "templates/contact/contact";
@import "templates/contact/location";
@import "templates/clients/clients";
@import "templates/clients/client";
@import "templates/privacy/privacy";

@import "fcbs-styling";

  html{
    visibility: visible !important;
    opacity: 1 !important;
  }