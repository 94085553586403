.blockquote {
	margin: 0;
  line-height: 1.1;
}

.blockquote__text {
  &::before {
    content: '\201C';
    margin-left: -0.4em;
  }
  &::after {
    content: '\201D';
  }
	font-family: $font-body;
  font-weight: 1000;
	font-size: rem(24);

  @include media($m-screen-up) {
    font-size: rem(27);
  }
}