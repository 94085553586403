.project,
.practice {
  @include wrapper($gutter-extra-large);

  .section__intro {
    padding-left: 0;
    padding-right: 0;
  }


  .section__image,
  .section__video {
    padding-left: 0;
    padding-right: 0;
    max-height: 70vh;
    overflow: hidden;
  }

  .project__title {
    font-size: 1.5rem;
  }
}

.project__row {
  padding-bottom: 1.5625rem;

  a{
    text-decoration: none !important;
  }
}


.project__subtitle {
  font-family: $font-body;
  text-transform: none;
  font-size: rem($text-small);

  @include media($s-screen-up) {
    font-size: rem($text-small);
  }
}