.card {
  display: block;
  position: relative;
  overflow: hidden;
  padding: $gutter;
  aspect-ratio: 4 / 3;

  @include media($xl-screen-down) {
    .icon--arrow-small {
      display: inline-block;
    }

    .icon--arrow-medium {
      display: none;
    }
  }

  @include media($xl-screen-up) {
    .icon--arrow-small {
      display: none;
    }

    .icon--arrow-medium {
      display: inline-block;
    }
  }
}

.card--image {
  background: $light-green;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    width: 177.77777778%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card--simple-link {
  background: $dark-green;

  .card__title {
    color: $green;
  }
}

.card--text-link {
  background: $green;
  color: $dark-green;

  .card__label {
    color: $dark-green;
  }
}

.card--text {
  background: $green;
  color: $dark-green;
}

.card--explore {
  @include col(2);

  @include media($s-screen-up) {
    @include col(1);
  }
}

.card--simple-link,
.card--text-link {
  text-decoration: none;

  .icon {
    position: absolute;
    bottom: $gutter;
    right: $gutter;

    &.icon--white svg line,
    &.icon--white svg polyline {
      transition: stroke 0.33s;
    }
  }

  &:hover {

    .icon--white svg line,
    .icon--white svg polyline {
      stroke: $black;
      transition-delay: 0.1s;
    }
  }
}

.card--featured {
  position: relative;
  margin-bottom: $gutter;
  height: 0;
  text-decoration: none;
  aspect-ratio: auto;
  font-size: rem(18);
  // padding-bottom: 60%;

  // @include media($l-screen-up) {
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(6) {
    padding-bottom: 80%;
  }

  &:nth-child(3) {
    padding-bottom: 60%;
  }

  &:nth-child(4) {
    padding-bottom: 100%;
  }
  // }
  span {
    z-index: 2;
  }
  &.card--image::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 33%;
    display: inline-block;
    background: linear-gradient(to top, black, transparent);
    opacity: .6;
  }
}


.card--before {
  position: relative;

    span {
    z-index: 2;
  }
  &.card--image::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 33%;
    display: inline-block;
    background: linear-gradient(to top, black, transparent);
    opacity: .6;
  }
}

.card__details {
  position: relative;
}

.card__title,
.card__text {
  position: relative;
  @extend h3;
}

.card__date {
  position: relative;
  color: $white;

  .card:hover & {
    color: $black;
  }
}

.card__label {
  position: absolute;
  font-weight: bold;
  bottom: $gutter;
  left: $gutter;
  padding-right: 4rem;
  color: $white;
  transition: color 0.5s 0.1s;
}

.card__overlay{
  .card__label{
    color: $black;
  }
}

.card__overlay {
  display: block;
  position: absolute;
  width: 102%;
  height: 102%;
  top: 0;
  left: 0;
  padding-left: $gutter;
  padding-right: $gutter;
  padding-top: calc($gutter + 1%);
  padding-bottom:  calc($gutter + 2%);
  background: $light-green;
  transform: translate3d(-100%, 100%, 0);
  transition: transform 1s $easing-quintic;

  .card:hover & {
    transform: translate3d(-1%, -1%, 0);
    transition-delay: .25s;
  }
}

.card__overlay-label,
.card__overlay-title {
  display: block;
}

.card__overlay-title {
  @extend h5;
  margin-top: $gutter-small;
  font-family: Judge,sans-serif;
  line-height: 1;
  text-transform: uppercase;
}

.card__overlay-arrow {
  position: absolute;
  right: $gutter;
  bottom: $gutter;
}