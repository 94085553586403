.location__image {
  img {
    display: block;
    width: 100%;
  }
}

.location__link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
