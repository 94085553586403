.contact {
  @include wrapper($gutter-extra-large);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @include media($s-screen-up) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media($l-screen-up) {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.contact__grid {
  @include grid;
  @include rowGap($gutter-large);
  @include media($m-screen-up) {
    @include colGap($gutter-medium);
  }
}

.contact__item {
  @include col;
  @include media($m-screen-up) {
    @include col(6);
  }
}
