.breakout {
  &--light-green {
    background: $light-green;
  }

  &--green {
    background: $green;
    color: $dark-green;
  }
}

.breakout__inner {
  @include wrapper($gutter-extra-large);

  &--extra-padding {
    padding-top: $gutter-extra-large;
    padding-bottom: $gutter-extra-large;
  }
}

.breakout__text {
  margin-bottom: $gutter;

  h2,
  p {
    margin-bottom: $gutter;
  }
}

.breakout__link {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  justify-content: space-between;
}

.breakout--default {
  .breakout__title {

    font-size: 5.625rem;
    line-height: 0.9;
    margin-bottom: $gutter;
  }

  .breakout__body {
    position: relative;
    font-family: 'Grotesc';
    font-weight: bold;
    font-size: 1.8rem;
    text-align: left;
  }
}

.breakout--link {
  .breakout__text {
    // max-width: rem(600);

    p {
      @include media($m-screen-up) {
        font-size: 1.875rem;
        margin-bottom: $gutter-extra-large;
      }
    }
  }
}

.breakout--link-with-image {
  .breakout__inner {
    @include media($xl-screen-up) {
      // @include grid(12);
      // @include colGap();
      padding-top: $gutter-extra-large;
      padding-bottom: $gutter-extra-large;
    }
  }

  .breakout__text {
    @include media($xl-screen-up) {
      // @include col(4);
    }
  }

  .breakout__image {
    position: relative;

    img {
      width: 100%;
    }

    @include media($xl-screen-up) {
      // @include col(8);
      // width: calc(100% + $gutter-large);
    }
  }
}