.section {

  &--padding-top {
    padding-top: $gutter-large;

    @include media($l-screen-up) {
      padding-top: $gutter-extra-large;
    }
  }

  &--padding-top-small {
    padding-top: $gutter-large;
  }

  &--padding-bottom {
    padding-bottom: $gutter-large;

    @include media($l-screen-up) {
      padding-bottom: $gutter-extra-large;
    }
  }

  &--padding-bottom-small {
    padding-bottom: $gutter-large;
  }

  &--padding-bottom-small {
    padding-bottom: $gutter-large;
  }

  &--light-green {
    background: $light-green;
  }

  &--black {
    background: $black;
    color: $white;
  }

  &--dark-green {
    background: $dark-green;
    color: $white;
  }

  &--border-top {
    position: relative;

    &::before {
      display: block;
      content: '';
      height: rem(1);
      background: $green;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: $gutter;
      width: calc(100% - #{$gutter});

      @include media($m-screen-up) {
        margin-left: $gutter-large;
        width: calc(100% - #{$gutter-large});
      }
    }
  }
}

.section__modules {

  &>section:last-child {
    margin-bottom: $gutter-large;

    @include media($l-screen-up) {
      margin-bottom: $gutter-extra-large;
    }
  }

  // This removes margin bottom on modules that have a coloured background
  // So that they sit flush with the bottom border
  &>section.has-bg:last-child {
    margin-bottom: 0;
  }
}

.section__text-container {
  // max-width: rem(700);
}

.section__columns {
  @include grid;
  @include rowGap($gutter-large);

  @include media($l-screen-up) {
    @include colGap($gutter-medium);
  }
}

.section__column {
  @include col;

  .section__columns--2 & {
    @include media($l-screen-up) {
      @include col(6);
    }
  }

  .section__columns--3 & {
    @include media($l-screen-up) {
      @include col(4);
    }
  }
}

.section__title {
  @extend h4;
  line-height: 1;

  @include media($m-screen-up) {
    &::before {
      content: "";
      margin-bottom: -0.135em;
      display: table;
    }

    &::after {
      content: "";
      margin-top: -0.115em;
      display: table;
    }
  }
}

.page__title {
  @extend h2;
  line-height: 1;

  @include media($m-screen-up) {
    &::before {
      content: "";
      margin-bottom: -0.135em;
      display: table;
    }

    &::after {
      content: "";
      margin-top: -0.115em;
      display: table;
    }
  }
}

.section__title--small {
  @extend h5;
}

.section__meta {
  margin-top: $gutter-small;
}

.section__category {
  font-weight: bold;
}

.section__heading {
  @extend h4;
}

.section__subheading {
  font-size: rem(24);
  line-height: math.div(29, 24);
  font-weight: bold;
  text-transform: none;
  font-family: $font-body;

  padding-bottom: $gutter-small;
  border-bottom: $border-primary;
}

.section__text {
  // line-height: 1.2;
  p{
    margin-top: $gutter-small;
  }

  &--large,
  .section__text-container--large & {
    @include media($s-screen-up) {
      font-size: rem(20);
    }
  }
}

.section__link {
  display: inline-flex;
}

.section__blockquote {
  max-width: rem(700);
}

.section__image {
  position: relative;
  //max-width: rem(800);

  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;

    &.half-width {
      @include media($m-screen-up) {
        width: 50%;
      }
    }
  }

  .project & {
    max-width: none;
  }
}

.section__image--no-padding {
  padding: 0;
}

.section__video {
  max-width: rem(800);

  .project & {
    max-width: none;
  }
}

.section__rows {
  // max-width: rem(800);

  .project & {
    // max-width: none;
  }
}

.section__list {
  padding-left: 0;
  list-style: none;
}

.section__list-item {
  strong {
    color: $green;
  }
}

.section__grid {
  @include grid(1);
  @include rowGap;
  @include colGap;

  @include media($s-screen-up) {
    @include grid(2);
  }

  @include media($m-screen-up) {
    @include grid(1);
  }

  @include media($l-screen-up) {
    @include grid(2);
  }
}

.section__intro {
  @include grid;
  @include rowGap($gutter-large);

  @include media($l-screen-up) {
    @include colGap($gutter-medium);
  }
}

.section__intro-text {
  @include col;

  @include media($l-screen-up) {
    @include col(7);
  }

  @include media($xl-screen-up) {
    @include col(6);
  }
}

.section__intro-summary {
  @include col;

  @include media($l-screen-up) {
    @include col(5);
  }

  @include media($xl-screen-up) {
    @include col(4, 9);
  }
}

.section__intro-links {
  @include col;

  @include media($l-screen-up) {
    @include col(5);
    text-align: right;
  }

  @include media($xl-screen-up) {
    @include col(4, 9);
  }
}

.section__intro-link {
  text-decoration: none;
  font-weight: bold;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.section__related {
  position: relative;

  &::before {
    display: block;
    content: '';
    height: rem(1);
    background: $green;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
  }
}