*,
:before,
:after {
  box-sizing: border-box !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  // This is a new standard, currently only works in FF
  scrollbar-gutter: stable;
  scrollbar-color: $green $light-green;
  scrollbar-width: thin;

  &.transition-active {
    cursor: wait;
  }

  &.search-is-active {
    overflow: hidden;
  }
}

body {
  position: relative;
  font-family: $font-body;
  font-size: rem(20);
  color: $black;
  overflow-x: hidden;
  padding-bottom: $bar;

  @include media($m-screen-up) {
    padding-bottom: 0;
  }
}

main {
  position: relative;
  // Overflow hidden breaks Approach section sticky nav: https://stackoverflow.com/a/58295375
  // overflow-x: hidden;
}

a {
  color: currentColor;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}
