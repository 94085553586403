.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $white;
  z-index: 1000;
  overflow: auto;
  display: none;
  opacity: 0;
}

.search__container {
  @include wrapper($gutter-extra-large);
  display: flex;
  min-height: 100%;

  .cols {
    width: 100%;
  }

  .cols__right {
    @include media($m-screen-up) {
      min-height: 100%;
    }
  }
}

.search__form {
  display: flex;
  width: 100%;
  border: $border-primary;
  display: flex;
  margin-top: 0;
}

.search__input-container {
  position: relative;
  width: 100%;
}

.search__input-warning {
  padding: $gutter-small;
  width: 100%;
  height: 100%;
  align-items: center;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  display: none;

  .search-is-empty & {
    display: flex;
  }
}

.search__input {
  padding: $gutter-small;
  border-radius: 0;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: bold;
  width: 100%;
  height: 100%;

  @include media($l-screen-up) {
    font-size: rem(24);
  }

  &::placeholder {
   color: $grey;
  }
}

.search__submit {
  @include btnReset;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(14);
  font-size: 0;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: rem(30);
    height: rem(30);
    border: rem(3) solid rgba($green, 0.2);
    border-bottom-color: $green;
    border-radius: 50%;
    animation: loader 0.5s linear infinite;
    opacity: 0;

    .search-in-progress & {
      opacity: 1;
    }
  }
}

.search__arrow {
  display: block;
  width: rem(29);

  .search-in-progress & {
    opacity: 0;
  }
}

.search__results {
  @include grid(2);
  justify-content: space-between;
  @include rowGap;
  @include media($m-screen-up) {
    @include colGap;
  }
}

.search__result {
  @include col(2);
  @include media($m-screen-up) {
    @include col(1);
  }

  &--person {
    .card {
      padding: 0 !important;
      padding-bottom: 100% !important;
      aspect-ratio: unset !important;
    }
  }
}

.search__close {
  @include btnReset;
  position: absolute;
  top: $gutter;
  right: $gutter;

  .icon svg {
    width: rem(19);
    height: rem(19);
  }

  @include media($m-screen-up) {
    top: $gutter-large;
    right: $gutter-medium;
  }
}

.search__card-templates {
  display: none;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
