.rows {
  display: flex;
  flex-direction: column;
}

.rows__row {
  display: flex;
  gap: 5px;
  align-items: baseline;
  font-size: rem($text-small);
    padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      
  +#{&} {
    border-top: $border-primary;

  }

  .labelPart {
    font-weight: 700;
    width: 49%;
  }

  .contentPart {
    width: 49%;
    margin-left: 2%;
  }
}

.row__year {
  margin-right: $gutter;

  @include media($xl-screen-up) {
    margin-right: $gutter;
  }
}

.collection {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  @include media($l-screen-up) {
    gap: 40px;
  }

  @include media($xxl-screen-up) {
    gap: 0;
  }

  .section__column {
    margin-top: 0;
    width: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 1.2rem;
  }

  >:nth-child(2) {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (max-width: 1220px) {
  .collection {
    flex-direction: column;
    .section__column {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }



  }
}