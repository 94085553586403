.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: $gutter;
  padding: $gutter;
  z-index: 100;

  .container--center & {
    position: absolute;
    top: 0;
    left: 0;
  }

  .container--normal & {
    position: relative;
  }

  @include media($m-screen-up) {
    padding: $gutter-large $gutter-large 0;

    .container--center & {
      padding: $gutter-large calc($gutter-large + $bar) 0;
    }

    .container--normal & {
      padding: $gutter-large $gutter-large 0;
    }
  }

  &__logo {
    display: block;
    width: 100%;
    max-width: 350px;

    @include media($m-screen-up) {
      max-width: 405px;
    }

    svg {
      width: 100%;

      path {
        .container--normal &,
        .container--left &,
        .container--right & {
          fill: $black;
        }
      }
    }
  }

  &__search {
    text-decoration: none;
  }
}
