.award {
  @include col(2);
  display: flex;
  padding-top: $gutter;
  border-top: $border-primary;

  @include media($l-screen-up) {
    @include col(1);
  }
}

.award__year {
  text-transform: none;
  font-family: $font-body;
  font-size: rem($text-regular);
  margin-right: $gutter;
}

.award__title {
  text-transform: none;
  font-family: $font-body;
  font-size: rem($text-regular);
}

.award__project {
  text-transform: none;
  font-family: $font-body;
  font-size: rem($text-regular);
  font-weight: normal;
  text-decoration: none;

  &--link {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}