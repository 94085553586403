.featured__container {
  @include wrapper($gutter-extra-large);
}

.featured__header {
  width: 100%;

  h2 {
    @extend h4;
  }
}
