.person {
	text-decoration: none;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	aspect-ratio: 1 / 1;	
}

.person__details {
	position: relative;
	padding: $gutter;
	color: $white;
}

.person__name {
	position: relative;
	font-family: $font-body;
	text-transform: none;
	font-size: rem($text-regular);
}

.person__placeholder,
.person__image {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
  object-fit: cover;
}

.person__placeholder {
	background: $green;
}
