.project-row {
  display: flex;
  @include col;

  @include media($l-screen-up) {
    @include col(6);
  }

  @include media($xl-screen-up) {
    @include col(4);
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(8n + 1),
  &:nth-child(8n + 2) {
    @include media($l-screen-up) {
      @include col(6);
    }
  }

  .list-view-is-active & {
    @include col;
  }

  .card {
    width: 100%;
  }

  a{
    text-decoration: none;
  }
}

.list-view-is-active {
  .project-row+.project-row {
    border-top: $border-primary;
  }
}

.project-row__container {
  display: grid;
  width: 100%;
  font-size: rem($text-small);

  .list-view-is-active & {
    padding-top: $gutter;
  }

  .project-row:first-child & {
    padding-top: 0;
  }
}

.project-row__heading {
  text-transform: none;
  font-family: $font-body;
  font-size: rem($text-small);
}

.project-row__content {
  display: none;

  width: 100%;

  @include media($l-screen-up) {
    @include colGap($gutter-large);
  }

  @include media($l-screen-down) {
    .row__image {
      grid-column: 1 / 4;
    }
  }

  .list-view-is-active & {
    @include grid(6);
  }
  gap: 10px;
}

.project-row__col {
  @include col(5);

  @include media($l-screen-up) {
    @include col(1);
  }

  &--two {
    @include media($l-screen-up) {
      @include col(2);
    }
  }
}

.list-view-is-active .card {
  width: 100%;
  flex-shrink: 1;
  max-width: rem(170);
  margin-right: $gutter;
  height: 10vh !important;

  @include media($l-screen-up) {
    max-width: rem(160);
  }

  *:not(img) {
    display: none;
  }

}