.filter-text {
  display: none;
  padding-top: $gutter-medium;
  max-width: rem(800);

  @include media($l-screen-up) {
    padding-top: $gutter-large;
    padding-bottom: $gutter;
    font-size: rem(24);
  }

  &.filter-is-active {
    display: block;
  }
}