.nav {
  $panel-width: calc(100% - $bar * 2);
  $loading-transition-duration: 0.7s;

  display: block;
  position: relative;

  &__text {
    position: relative;
    pointer-events: none;

    @include media($m-screen-down) {
      left: 0;
      transition: left 0.33s $easing-quintic;
    }

    @include media($m-screen-up) {
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
    }

    .icon {
      position: absolute;
      left: calc(rem(-26) - $gutter-small);
      bottom: 0;
      transform: translateX(rem(-20));
      opacity: 0;
      transition: all 0.33s $easing-quintic;

      .active & {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .active & {
      // This is to offset the active arrow on mobile
      @include media($m-screen-down) {
        left: rem($text-regular);
      }
    }
  }

  &__link {
    display: flex;
    position: fixed;
    bottom: -0.5vh;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 101vh;
    background: $white;
    font-family: $font-heading;
    font-size: rem(28);
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1000;
    transition:
      color 0.1s 0.1s,
      border 0s;

    svg{
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 100%;
      background: $dark-green;
      transition: width 0.45s $easing-quintic;
    }

    &--can-hover {
      &:hover, &--hover {
        color: $white;
        border-color: $dark-green;
        transition:
          color 0.1s 0.1s,
          border 0s 0.33s;

        &::before {
          width: 100%;
        }
      }
    }

    .transition-active & {
      pointer-events: none;
    }

    @include media($m-screen-up) {
      justify-content: normal;

      width: $bar;



      &.is-moving {
        background: $dark-green;
        border-color: $dark-green;
        color: $white;
      }
    }

    &--left {
      left: 0;
      border-right: $border-primary;

      &::before {
        left: 0;
      }

      .nav__loader {
        left: 1px;
        transform: translateX(-100%);
      }

      @include media($m-screen-up) {
        &.move-left {
          left: $panel-width;
          transition: left $loading-transition-duration $easing-quintic;
        }

        &.active {
          left: $panel-width;
          border-right: none;
          border-left: $border-primary;
        }

        &.reset-left {
          left: 0;
          transition: left $loading-transition-duration $easing-quintic;
        }
      }
    }

    &--right {
      right: 0;
      border-left: $border-primary;

      &::before {
        right: 0;
      }

      .nav__loader {
        right: 1px;
        transform: translateX(100%);
      }

      @include media($m-screen-up) {
        &.move-right {
          right: $panel-width;
          transition: right $loading-transition-duration $easing-quintic;
        }

        &.active {
          right: $panel-width;
          border-left: none;
          border-right: $border-primary;
        }

        &.reset-right {
          right: 0;
          transition: right $loading-transition-duration $easing-quintic;
        }
      }
    }
  }

  &__loader {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(100vw - $bar * 2);
    background: $dark-green;
    transition: width $loading-transition-duration $easing-quintic;

    @include media($m-screen-up) {
      display: block;
    }

    &.hidden {
      display: none;
    }

    &.collapse {
      width: 0;
    }
  }

  &__mobile-loader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - $bar);
    background: $dark-green;
    z-index: 1000;
    transition: transform $loading-transition-duration $easing-quintic;

    @include media($m-screen-up) {
      display: none;
    }

    &.hidden {
      display: none;
    }

    &.left {
      transform: translateX(-100%);
    }

    &.right {
      transform: translateX(100%);
    }

    &.active {
      transform: translateX(0);
    }
  }
}
