.approach {
	
}

.approach__nav {
	@include listReset;
	width: 100%;
  margin-top: 0;
}

.approach__nav-item {
	display: block;
}

.approach__link {
	display: inline-block;
	text-decoration: none;
	text-transform: capitalize;

	&.scroll-link-is-active {
		font-weight: bold;
	}
}

