.carousel {
  width: 100%; // calc(100% - 2.5rem);
  overflow: hidden;

  @include media($l-screen-down) {
    width: 100%; // calc(100% - 1.25rem);
    padding-right: 1.25rem;
  }
}

$carouselHeight: 400;
$carouselLargeHeight: 600;

.carousel__container {
  width: 100%;
  height: rem($carouselHeight / 3);

  @include media($s-screen-up) {
    height: rem($carouselHeight / 2);
  }

  @include media($l-screen-up) {
    height: rem($carouselHeight);
  }

  .carousel__slide {

    height: rem($carouselHeight / 3);

    @include media($s-screen-up) {
      height: rem($carouselHeight / 2);
    }

    @include media($l-screen-up) {
      height: rem($carouselHeight);
    }

    img {
      height: rem($carouselHeight / 3);

      @include media($s-screen-up) {
        height: rem($carouselHeight / 2);
      }

      @include media($l-screen-up) {
        height: rem($carouselHeight);
      }
    }

    margin-right: $gutter;

    &--video {
      max-width: 100%;
      aspect-ratio: 16 / 9;
    }

    .video {
      width: 100%;
      height: 100%;
      aspect-ratio: initial;

      &::after {
        display: none;
      }
    }
  }

  &--large {
    height: rem($carouselLargeHeight / 3);

    @include media($s-screen-up) {
      height: rem($carouselLargeHeight / 2);
    }

    @include media($l-screen-up) {
      height: rem($carouselLargeHeight);
    }

    .carousel__slide {

      height: rem($carouselLargeHeight / 3);

      @include media($s-screen-up) {
        height: rem($carouselLargeHeight / 2);
      }

      @include media($l-screen-up) {
        height: rem($carouselLargeHeight);
      }

      img {
        height: rem($carouselLargeHeight / 3);

        @include media($s-screen-up) {
          height: rem($carouselLargeHeight / 2);
        }

        @include media($l-screen-up) {
          height: rem($carouselLargeHeight);
        }
      }
    }
  }
}



.carousel__controls {
  padding-top: $gutter-small;
  display: flex;
  align-items: baseline;
}

.carousel__buttons {
  display: flex;
  margin-right: $gutter-small;
}

.carousel__button {
  @include btnReset;
  display: block;

  &--prev {
    margin-right: $gutter-small;
  }
}

.slider__numbers {
  line-height: 1;
  display: flex;
  // So numbers don't move when changing index
  font-variant-numeric: tabular-nums;
}