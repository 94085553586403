.standard-button {
  font-family: $font-heading;
  text-transform: uppercase;
  background: $green;
  color: $white;
  padding: $gutter-small;
  width: min-content;
  white-space: nowrap;
  cursor: pointer;
  transition: background .1s, color .1s;
  &:hover {
    background: $light-green;
    color: $black;
  }
}