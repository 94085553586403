.quote {
  position: relative;
  background: $light-green;
  line-height: 1.1;

  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   background: $light-green;
  //   height: 100%;
  //   width: calc(100% + #{$gutter-large});
  //   left: -$gutter;

  //   @include media($m-screen-up) {
  //     width: calc(100% + #{$gutter-extra-large});
  //     left: -$gutter-large;
  //   }

  //   @include media($l-screen-up) {
  //     width: calc(100% + #{$gutter-large} + #{$gutter-medium});
  //     left: -$gutter-medium;
  //   }
  // }

  // .project & {
  //   &::before {
  //     width: calc(100% + #{$gutter-large});
  //     left: -$gutter;

  //     @include media($m-screen-up) {
  //       width: calc(100% + #{$gutter-extra-large});
  //       left: -$gutter-large;
  //     }

  //     @include media($l-screen-up) {
  //       width: calc(100% + #{$gutter-large} + #{$gutter-large});
  //       left: -$gutter-large;
  //     }
  //   }
  // }
}

.quote__blockquote {
  position: relative;
  margin: 0;
  padding: $gutter;

  @include media($l-screen-up) {
    padding-left: $gutter-large;
    padding-right: $gutter-large;
    padding-top: $gutter-extra-large;
    padding-bottom: $gutter-extra-large;
  }
}

.quote__text {
  max-width: rem(700);

  &::before {
    content: '\201C';
  }

  &::after {
    content: '\201D';
  }

  font-family: $font-body;
  font-weight: 1000;
  font-size: rem(24);
  line-height: 1;

  @include media($m-screen-up) {
    font-size: rem(34);
  }
}

.quote__source {
  font-size: rem($text-small);
  margin-top: 1rem;
}