.back {
  display: flex;
  align-items: baseline;
  font-size: rem($text-small);
  font-weight: bold;
  text-decoration: none;

  @include media($s-screen-up){
    font-size: rem($text-small);
  }
}

.back__arrow {
  margin-left: $gutter-small;
}
