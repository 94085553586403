$module-gap: 3vh;

$module-v-padding: 60px;
$module-h-padding: 20vw;
$project-v-padding: 40px;
$project-h-padding: 80px;

$small-v-padding: 20px;
$small-h-padding: 20px;
$medium-v-padding: 40px;
$medium-h-padding: 40px;

$central-width: 60vw;
$central-min-width: 1105px;

$standard-gap: 5vh;
$large-gap: 8vh;
$sm-card-height: 23vh;


.home__caro__light{
  position: relative;
  min-height: 90vh;
  overflow: hidden;

.glide__arrow{
  background: none;
    border: none;
    color: inherit;
    display: inline-flex;
    font-family: inherit;
    left: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    z-index: 99;
}

.glide__arrow--right{
      left:unset;
      right:0;

}

  .slide{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;


    iframe, img{
      width: 100%;
      height: 100%;
    }

  }

  .left-start{
    position:absolute;
    left: 100%;
    top: 0;
    transition: left ease-in-out 0.7s;
    z-index: -1;
  }

  .right-start{
    left: -100% ;
  }

  .bg-slide{
        left: 0;
    z-index: 2;
  }

  .moving-right{
    left: 0;
    z-index: 3;
  }
}

.related{
  .card__overlay-title{
    font-size: clamp(2vh, 2.5vh, 3vh);
  }
  .card__label{
    font-size: clamp(2vh, 2vh, 3vh);
  }
}

li {
  list-style: none;
}


.flex__outer {
  display: flex;


  .content__wrapper {
    width: $central-width;
    min-width: $central-min-width;
    margin-left: auto;
    margin-right: auto;
  }

  .full__bleed {
    width: 100%;
  }

  @include media ($xxl-screen-down) {
    .content__wrapper {
      width: 100%;
      min-width: unset;
      margin-left: auto;
      margin-right: auto;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}

.no__ul{
text-decoration: none;
}
.apply__btn{
 padding: 10px;
 font-family: 'Judge';
 text-transform: uppercase;
 background-color: #00cf6b;
 color:#fff;
font-size: 28px;
text-align: center;
 &:hover{
  cursor: pointer;
 }
}
 
.standard__gap {
  margin-bottom: $standard-gap;
}

.large__gap {
  margin-bottom: $large-gap;
}


.project,
.practice {
  .content__wrapper {
    width: 90%;
    min-width: unset;
  }
}

.explore__cols{
    .content__wrapper {
      width: 95%;
      min-width: unset;
    }
}

.section {
  padding-left: $small-h-padding;
  padding-right: $small-h-padding;
}

.clients{
  .section{
    padding-left: $medium-h-padding;
    padding-right: $medium-h-padding;
  }
}

.module {
  &__inner-padding {
    padding-top: $small-v-padding;
    padding-bottom: $small-v-padding;
  }


  @include media ($m-screen-up) {
    &__inner-padding {
      padding-top: $medium-v-padding;
      padding-bottom: $medium-v-padding;
    }

    &__padding,
    &__inner-padding {
      margin-bottom: $medium-v-padding;
    }
  }

  @include media($xl-screen-up) {
    &__inner-padding {
      padding-top: $module-v-padding;
      padding-bottom: $module-v-padding;
    }

    &__padding,
    &__inner-padding {
      margin-bottom: $module-v-padding;
    }
  }
}

.row {
  padding-top: $module-gap;
  padding-bottom: $module-gap;
}

.module__grid {
  display: grid;
  align-items: start;
  grid-auto-rows: min-content;
  gap: $module-gap;

  &--half {
    grid-template-columns: 1fr;
    gap: $module-gap;
  }

  &--one-third {
    grid-template-columns: 1fr;
    gap: $module-gap;
  }

  &--two-thirds-right {
    grid-template-columns: 1fr;
    gap: $module-gap;
  }

  &--two-thirds {
    grid-template-columns: 1fr;
    gap: $module-gap;
  }

  &--twelves {
    grid-template-columns: 1fr;
    gap: $module-gap;
  }

  @include media($xl-screen-up) {
    &--half {
      grid-template-columns: 1fr 1fr;
    }

    &--one-third {
      grid-template-columns: 1fr 2fr;
    }

    &--two-thirds {
      grid-template-columns: 2fr 1fr;
    }

    &--two-thirds-right {
      grid-template-columns: 1fr 2fr;
      gap: $module-gap;
    }

    &--twelves {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  >*.grid__item {
    &--span-two {
      grid-column: span 1;
    }

    &--span-three {
      grid-column: span 1;
    }

    &--span-six {
      grid-column: span 1;
    }
  }

  @include media($xl-screen-up) {
    >*.grid__item {
      &--span-two {
        grid-column: span 2;
      }

      &--span-three {
        grid-column: span 3;
      }

      &--span-six {
        grid-column: span 6;
      }
    }
  }
}

.mb__spacing {

  .section__text,
  .section__link,
  .section__blockquote,
  .link--special {
    padding-bottom: 10px;
  }

  @include media($xl-screen-up) {

    .section__text,
    .section__link,
    .section__blockquote,
    .link--special {
      padding-bottom: $medium-v-padding;
    }
  }

  .link--special {
    a {
      text-decoration: none;
    }
  }
}

.row__image {
  height: 10rem;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}

.people {
  margin-top: $standard-gap;
}

.no__pad__bottom,
.practice {
  padding-bottom: 0;
}

.modular__section {
  >:last-of-type {

    .module__padding,
    .module__inner-padding,
    .standard__gap,
    .large__gap {
      margin-bottom: 0 !important;
    }
  }
}

.explore{
  .section__text{
    margin-bottom: $module-gap;
  }
}

.logo--white, .footer__logo{
  svg{
  fill: #fff !important;

  }
}

.section__list-item{
  h3{
    display: flex;

    strong{
      margin-right: 10px;;
    }
  }
}

.section__text{
  p{
      white-space: pre-line !important;
      line-height: 1.25;
  }
}

.breakout--green{
  p{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.section--dark-green{
  p{
    line-height: 0.8;
  }
}

.profile__bio{
  p{
    margin-top: 0.625rem;
  }
}

.mini__spacer{
  margin-top: 10px;
}

.quote__text{
  text-transform: none !important;
}

.contact{
  padding-top: 15vh;
}

.subnav__items--utils{
  display: flex;
  margin-left: auto;
  align-items: center;

}

.sort-options{
  display: flex;
  margin-top: 5px;

  .space-right{
    margin-right: 10px;;
  }

    input[type='radio']{
      z-index: -1;
      margin-right: 5px;
      accent-color: #00cf6b;
    }

    input[type='radio']:after {
        width: 17px;
        height: 17px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        outline: 1px solid #00cf6b;
    }

    input[type='radio']:checked:after {
        width: 17px;
        height: 17px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: #00cf6b;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
          outline: 1px solid #00cf6b;
    }
  
}

//overrides for mobile -- kep here until signed off

.mb__spacing {
  .section__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

    @include media ($xxl-screen-down) {
      .related {
        grid-template-columns: repeat(8, 1fr);
      }
    }

    @include media ($m-screen-down) {
      .related{
           grid-template-columns: auto;
      }
      .full__bleed{
        padding-top: 40px;
        padding-bottom: 40px;
      }

      .breakout{
        .module__grid--half {
          gap: 0 !important;
        }
      }

      .breakout__title{
        font-size: 3rem !important;
        margin-bottom: 0 !important;
      }

      .breakout__body{
        font-size:  1.3rem !important;
        text-align: left !important;
      }

      .card--featured{
        height: 45vh !important;
        padding-bottom: 0 !important;
      }

      .card__overlay-title, .card__title{
        font-size:2rem;
      }

      .mb__spacing{
        .section__text{
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .mobile__padding{
        margin-bottom: $project-v-padding;
      }

      .content__wrapper{
        width: 95% !important;
      }
    }

    @include media ($m-screen-down){
      
      .flex__outer, .content__wrapper{
       
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      
      .link-container{
       right: $gutter;
      }

      .subnav__title {
        margin-right: 1rem; 
      }

.subnav__buttons{
  >:nth-of-type(2){
    margin-left: 1.2rem;
  }
}

      .nav__link{
        height: 60px;
        bottom: 0;
      }
    }

    @include media ($xl-screen-up) {
      .breakout {
        
        .breakout__body > span {
          grid-column-start: 2
        }
      }
    }

  @media screen and (max-width: 1420px) and (min-width: 1200px)  {
    .cols__left{
        width: 13rem;
    }

    .explore__cols{
      .card{
      .card__title{
        font-size: 2.125rem;
      }
    }
    }
  }

@media screen and (min-width: 766px){

.container--left{
  .link-container{
    right: 9rem;
  }
}
}

.landing__page{
  margin-top: 2vh;


   .content__wrapper{
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }

  .full__bleed{
    .content__wrapper{
    width: 60vw;
    min-width: 1105px;
    margin-left: auto;
    margin-right: auto;
    }
  }
}

.projects{
  .filter-text{
    padding-top: 1.25rem;
  }

  .space-below {
    padding-bottom: 2.5rem;
  }
}