@mixin flowH($val: $gutter) {
  >*+* {
    margin-left: $val;
  }
}

@mixin flowV($val: $gutter) {
  >*+* {
    margin-top: $val;
  }
}

@mixin hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px !important;
}

@mixin wrapper($verticalPadding: $gutter) {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  padding: $gutter;

  @include media($s-screen-up) {
    padding: $gutter-large;
  }

  @include media($l-screen-up) {
    padding-top: $verticalPadding;
    padding-bottom: $verticalPadding;
  }
}

@mixin sectionPaddingH() {
  padding-left: $gutter;
  padding-right: $gutter;

  @include media($m-screen-up) {
    padding-left: $gutter-large;
    padding-right: $gutter-large;
  }
}

@mixin listReset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin btnReset {
  background: none;
  padding: 0;
  font-family: inherit;
  border: none;
  color: inherit;
  display: inline-flex;
}

@mixin svgPad($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: percentage(math.div($height, $width));
  }

  svg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@mixin image($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: percentage(math.div($height, $width));
    background: $grey;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}